import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'product',
  initialState: { loading: false },
  reducers: {
    
    // CATEGORY REDUCER FUNCTION

    // CATEGORY FETCH LIST
    fetchProductCategoryListStart: stateLoadingStart,
    fetchProductCategoryListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_list`),
    fetchProductCategoryListFailure: stateError,

    // CATEGORY FETCH ITEM
    fetchProductCategoryItemStart: stateLoadingStart,
    fetchProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    fetchProductCategoryItemFailure: stateError,

    // CATEGORY CREATE ITEM
    createProductCategoryItemStart: stateLoadingStart,
    createProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    createProductCategoryItemFailure: stateError,

    // CATEGORY UPDATE ITEM
    updateProductCategoryItemStart: stateLoadingStart,
    updateProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    updateProductCategoryItemFailure: stateError,

    // CATEGORY DELETE ITEM
    deleteProductCategoryItemStart: stateLoadingStart,
    deleteProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_category`),
    deleteProductCategoryItemFailure: stateError,


    // PRODUCT REDUCER FUNCTION

    // PRODUCT FETCH LIST
    fetchProductProductListStart: stateLoadingStart,
    fetchProductProductListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_list`),
    fetchProductProductListFailure: stateError,

    // PRODUCT FETCH ITEM
    fetchProductProductItemStart: stateLoadingStart,
    fetchProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    fetchProductProductItemFailure: stateError,

    // PRODUCT CREATE ITEM
    createProductProductItemStart: stateLoadingStart,
    createProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    createProductProductItemFailure: stateError,

    // PRODUCT UPDATE ITEM
    updateProductProductItemStart: stateLoadingStart,
    updateProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    updateProductProductItemFailure: stateError,

    // PRODUCT DELETE ITEM
    deleteProductProductItemStart: stateLoadingStart,
    deleteProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_product`),
    deleteProductProductItemFailure: stateError,


    // PRODUCTSHOWCASE REDUCER FUNCTION

    // PRODUCTSHOWCASE FETCH LIST
    fetchProductProductshowcaseListStart: stateLoadingStart,
    fetchProductProductshowcaseListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productshowcase_list`),
    fetchProductProductshowcaseListFailure: stateError,

    // PRODUCTSHOWCASE FETCH ITEM
    fetchProductProductshowcaseItemStart: stateLoadingStart,
    fetchProductProductshowcaseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productshowcase_item`),
    fetchProductProductshowcaseItemFailure: stateError,

    // PRODUCTSHOWCASE CREATE ITEM
    createProductProductshowcaseItemStart: stateLoadingStart,
    createProductProductshowcaseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productshowcase_item`),
    createProductProductshowcaseItemFailure: stateError,

    // PRODUCTSHOWCASE UPDATE ITEM
    updateProductProductshowcaseItemStart: stateLoadingStart,
    updateProductProductshowcaseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productshowcase_item`),
    updateProductProductshowcaseItemFailure: stateError,

    // PRODUCTSHOWCASE DELETE ITEM
    deleteProductProductshowcaseItemStart: stateLoadingStart,
    deleteProductProductshowcaseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_productshowcase`),
    deleteProductProductshowcaseItemFailure: stateError,


  },
});

export const {
  fetchProductCategoryListStart, fetchProductProductListStart, fetchProductProductshowcaseListStart,
  fetchProductCategoryListSuccess, fetchProductProductListSuccess, fetchProductProductshowcaseListSuccess,
  fetchProductCategoryListFailure, fetchProductProductListFailure, fetchProductProductshowcaseListFailure,
  fetchProductCategoryItemStart, fetchProductProductItemStart, fetchProductProductshowcaseItemStart,
  fetchProductCategoryItemSuccess, fetchProductProductItemSuccess, fetchProductProductshowcaseItemSuccess,
  fetchProductCategoryItemFailure, fetchProductProductItemFailure, fetchProductProductshowcaseItemFailure,
  createProductCategoryItemStart, createProductProductItemStart, createProductProductshowcaseItemStart,
  createProductCategoryItemSuccess, createProductProductItemSuccess, createProductProductshowcaseItemSuccess,
  createProductCategoryItemFailure, createProductProductItemFailure, createProductProductshowcaseItemFailure,
  updateProductCategoryItemStart, updateProductProductItemStart, updateProductProductshowcaseItemStart,
  updateProductCategoryItemSuccess, updateProductProductItemSuccess, updateProductProductshowcaseItemSuccess,
  updateProductCategoryItemFailure, updateProductProductItemFailure, updateProductProductshowcaseItemFailure,
  deleteProductCategoryItemStart, deleteProductProductItemStart, deleteProductProductshowcaseItemStart,
  deleteProductCategoryItemSuccess, deleteProductProductItemSuccess, deleteProductProductshowcaseItemSuccess,
  deleteProductCategoryItemFailure, deleteProductProductItemFailure, deleteProductProductshowcaseItemFailure
} = slice.actions;

export default slice.reducer;
