import React from "react";
import Main from "layouts/main";
import AdminLogin from "components/common/login/admin";

function Home() {
  return (
    <Main>
      <AdminLogin />
    </Main>
  );
}

export default Home;
