import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Categories from "components/common/dashboard/categories";
import AdminLogin from "components/common/login/admin";

function Home() {
  return (
    <Dashboard activeItem={"categories"}>
      <Categories />
    </Dashboard>
  );
}

export default Home;
