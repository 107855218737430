import React, { useState, lazy } from "react";
const Nav = lazy(
  () => import(`components/${process.env.REACT_APP_SITE_SELECTOR_KEY}/nav`),
);
const Footer = lazy(
  () => import(`components/${process.env.REACT_APP_SITE_SELECTOR_KEY}/footer`),
);
const Main = ({ children, heroElement }) => {
  return (
    <>
      <Nav isHomePage={heroElement ? true : false} />
      <div style={{ minHeight: "60vh" }}>
        {heroElement}
        <div>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
