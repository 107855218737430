import React, { lazy } from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import AdminLogin from "components/common/login/admin";

const Settings = lazy(
  () =>
    import(
      `components/${process.env.REACT_APP_SITE_SELECTOR_KEY}/dashboard/settings`
    ),
);

function Home() {
  return (
    <Dashboard activeItem={"settings"}>
      <Settings />
    </Dashboard>
  );
}

export default Home;
