import React, { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Loader } from "semantic-ui-react";
import "App.css";

import Provider from "services/api-data";

import AdminLogin from "pages/common/admin/AdminLogin";
import AdminDashboard from "pages/common/admin/AdminDashboard";
import AdminDashboardProducts from "pages/common/admin/AdminDashboardProducts";
import AdminDashboardSuppliers from "pages/common/admin/AdminDashboardSuppliers";
import AdminDashboardCategories from "pages/common/admin/AdminDashboardCategories";
import AdminDashboardOrders from "pages/common/admin/AdminDashboardOrders";
import AdminDashboardPayments from "pages/common/admin/AdminDashboardPayments";
import AdminDashboardInvoices from "pages/common/admin/AdminDashboardInvoices";
import AdminDashboardNotifications from "pages/common/admin/AdminDashboardNotifications";
import AdminDashboardBills from "pages/common/admin/AdminDashboardBills";
import AdminDashboardCountriesAndRegions from "pages/common/admin/AdminDashboardCountriesAndRegions";
import AdminDashboardSettings from "pages/common/admin/AdminDashboardSettings";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Home = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/Home`),
);
const Products = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/Products`),
);
const Search = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/Search`),
);
const ProductDetail = lazy(() => import("pages/common/ProductDetail"));
const Category = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/Category`),
);
const Register = lazy(() => import("pages/common/Register"));
const TradeAssurance = lazy(
  () =>
    import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/TradeAssurance`),
);
const HelpCenter = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/HelpCenter`),
);
const ContactUs = lazy(() => import("pages/common/ContactUs"));
const PageNotFound = lazy(() => import("pages/common/pagenotfound"));
const Profile = lazy(() => import("pages/common/Profile"));
const Cart = lazy(() => import("pages/common/Cart"));
const AboutUs = lazy(
  () => import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/AboutUs`),
);
const PrivacyPolicy = lazy(
  () =>
    import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/PrivacyPolicy`),
);
const TermsAndConditions = lazy(
  () =>
    import(
      `pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/TermsAndConditions`
    ),
);
const CheckoutSuccess = lazy(
  () =>
    import(`pages/${process.env.REACT_APP_SITE_SELECTOR_KEY}/CheckoutSuccess`),
);
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
const BASE_URLS = {
  development: "http://localhost:8000/",
  production: process.env.REACT_APP_PRODUCTION_BASE,
};
export const API_BASE_URL = BASE_URLS[process.env.NODE_ENV];
const SITE_URLS = {
  development: "http://localhost:3000/",
  production: process.env.REACT_APP_PRODUCTION_SITE,
};
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const tk = localStorage.getItem("tk");
  const profile_type = localStorage.getItem("profile_type");
  useEffect(() => {
    if (!tk || profile_type !== "SUPPLIER") {
      navigate("/");
    }
  }, [profile_type, tk]);

  return children;
};

export const SITE_URL = SITE_URLS[process.env.NODE_ENV];

function App() {
  return (
    <Provider baseURL={API_BASE_URL}>
      <Router>
        <Suspense fallback={<Loader active inline style={{ margin: 10 }} />}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/search/:key" element={<Search />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/category" element={<Category />} />
            <Route path="/category/:category" element={<Category />} />
            <Route path="/register" element={<Register />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/helpcenter" element={<HelpCenter />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/trade" element={<TradeAssurance />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />

            <Route
              path="/checkout/checkout-success/:id"
              element={<CheckoutSuccess />}
            />

            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/products"
              element={
                <ProtectedRoute>
                  <AdminDashboardProducts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/suppliers"
              element={
                <ProtectedRoute>
                  <AdminDashboardSuppliers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/categories"
              element={
                <ProtectedRoute>
                  <AdminDashboardCategories />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/orders"
              element={
                <ProtectedRoute>
                  <AdminDashboardOrders />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin/dashboard/payments"
              element={
                <ProtectedRoute>
                  <AdminDashboardPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/invoices"
              element={
                <ProtectedRoute>
                  <AdminDashboardInvoices />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/notifications"
              element={
                <ProtectedRoute>
                  <AdminDashboardNotifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/bills"
              element={
                <ProtectedRoute>
                  <AdminDashboardBills />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/countries-and-regions"
              element={
                <ProtectedRoute>
                  <AdminDashboardCountriesAndRegions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/dashboard/settings"
              element={
                <ProtectedRoute>
                  <AdminDashboardSettings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
