import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Notification from "components/common/dashboard/notifications";
import AdminLogin from "components/common/login/admin";

function Home() {
  return (
    <Dashboard activeItem={"notifications"}>
      <Notification />
    </Dashboard>
  );
}

export default Home;
