import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import Main from "components/common/dashboard";
import AdminLogin from "components/common/login/admin";

function Home() {
  return (
    <Dashboard>
      <Main />
    </Dashboard>
  );
}

export default Home;
