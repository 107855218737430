import React, { lazy } from "react";
import { Link } from "react-router-dom";
import Dashboard from "layouts/dashboard";
import AdminLogin from "components/common/login/admin";

const Products = lazy(
  () =>
    import(
      `components/${process.env.REACT_APP_SITE_SELECTOR_KEY}/dashboard/products`
    ),
);

function Home() {
  return (
    <Dashboard activeItem={"products"}>
      <Products />
    </Dashboard>
  );
}

export default Home;
